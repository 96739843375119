import React, { createRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import axios from 'axios';
import logo from "../images/landing/logo.png";
import heroImg from "../images/landing/hero-img-opt.jpg";
import aboutImg from "../images/landing/about-img.png";
import icon1 from "../images/landing/icon1.png";
import icon2 from "../images/landing/icon2.png";
import icon3 from "../images/landing/icon3.png";
import icon4 from "../images/landing/icon4.png";
import beneImg from "../images/landing/bene-img-opt.png";
import aboutImg2 from "../images/landing/about-img2-opt.jpg";
import quoteIcon from "../images/landing/quote-icon.png";
import contactImg1 from "../images/landing/contact-img1-opt.png";
import footerBg from "../images/landing/footer-bg-opt.jpg";
import footerImg from "../images/landing/footer-img.jpg";

import { useForm } from "react-hook-form";

const LandingWa = (props) => {
  const { register, handleSubmit } = useForm();  
  const { t } = useTranslation();
  const lng = useLanguage();
  const location = useLocation();

  if (location.pathname === "/landing-wa") {
    i18n.changeLanguage("it");
  }

  const scrollDiv = createRef();

  const scrollSmoothHandler = () => {
    scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  };
  
  let international_prefix = [
    {label: "Prefix", value: ""},
    {label: "Albania +355", value: "+355"},
    {label: "Algeria +213", value: "+213"},
    {label: "Andorra +376", value: "+376"},
    {label: "Angola  +244", value: "+244"},
    {label: "Antigua +1-268", value: "+1-268"},
    {label: "Antille Olandesi  +599", value: "+599"},
    {label: "Arabia Saudita    +966", value: "+966"},
    {label: "Armenia +374", value: "+374"},
    {label: "Argentina         +54", value: "+54"},
    {label: "Australia         +61", value: "+61"},
    {label: "Austria +43", value: "+43"},
    {label: "Azerbaigian       +994", value: "+994"},
    {label: "Bahamas +1-242", value: "+1-242"},
    {label: "Bahrein +973", value: "+973"},
    {label: "Bangladesh        +880", value: "+880"},
    {label: "Barbados          +1-246", value: "+1-246"},
    {label: "Beilorussia       +375", value: "+375"},
    {label: "Belgio +32", value: "+32"},
    {label: "Belize  +501", value: "+501"},
    {label: "Benin   +229", value: "+229"},
    {label: "Bermuda +1-441", value: "+1-441"},
    {label: "Bhutan  +975", value: "+975"},
    {label: "Bolivia +591", value: "+591"},
    {label: "Bosnia Erzegovina +387", value: "+387"},
    {label: "Botswana          +267", value: "+267"},
    {label: "Brasile +55", value: "+55"},
    {label: "Brunei  +673", value: "+673"},
    {label: "Bulgaria          +359", value: "+359"},
    {label: "Burkina Faso      +266", value: "+266"},
    {label: "Burundi +257", value: "+257"},
    {label: "Cameroon          +237", value: "+237"},
    {label: "Canada  +1", value: "+1"},
    {label: "Capo Verde        +238", value: "+238"},
    {label: "Central Africa R. +236", value: "+236"},
    {label: "Ciad    +235", value: "+235"},
    {label: "Cile    +56", value: "+56"},
    {label: "Cina Popolare     +86", value: "+86"},
    {label: "Cipro   +357", value: "+357"},
    {label: "Colombia          +57", value: "+57"},
    {label: "Congo   +242", value: "+242"},
    {label: "Corea Nord      +850", value: "+850"},
    {label: "Corea Sud +82", value: "+82"},
    {label: "Costa D\"Avorio    +225", value: "+225"},
    {label: "Costa Rica        +506", value: "+506"},
    {label: "Croazia +385", value: "+385"},
    {label: "Cuba    +53", value: "+53"},
    {label: "Danimarca         +45", value: "+45"},
    {label: "Dominica          +1-767", value: "+1-767"},
    {label: "Ecuador +593", value: "+593"},
    {label: "Egitto  +20", value: "+20"},
    {label: "El Salvador       +503", value: "+503"},
    {label: "Emirati Arabi     +971", value: "+971"},
    {label: "Estonia +372", value: "+372"},
    {label: "Etiopia +251", value: "+251"},
    {label: "Filippine         +63", value: "+63"},
    {label: "Finlandia         +358", value: "+358"},
    {label: "Francia +33", value: "+33"},
    {label: "Gabon   +241", value: "+241"},
    {label: "Gambia  +220", value: "+220"},
    {label: "Georgia +995", value: "+995"},
    {label: "Germania +49", value: "+49"},
    {label: "Ghana   +233", value: "+233"},
    {label: "Giamaica          +1-876", value: "+1-876"},
    {label: "Giappone          +81", value: "+81"},
    {label: "Gibilterra        +350", value: "+350"},
    {label: "Gibuti  +253", value: "+253"},
    {label: "Giordania         +962", value: "+962"},
    {label: "Gran Bretagna +44", value: "+44"},
    {label: "Grecia  +30", value: "+30"},
    {label: "Grenada +1-473", value: "+1-473"},
    {label: "Groelandia        +299", value: "+299"},
    {label: "Guatemala         +502", value: "+502"},
    {label: "Guinea Bissau     +245", value: "+245"},
    {label: "Guinea Eq         +244", value: "+244"},
    {label: "Guyana  +592", value: "+592"},
    {label: "Haiti +509", value: "+509"},
    {label: "Hawai   +1-808", value: "+1-808"},
    {label: "Honduras          +504", value: "+504"},
    {label: "Hong Kong         +852", value: "+852"},
    {label: "India   +91", value: "+91"},
    {label: "Indonesia         +62", value: "+62"},
    {label: "Iran    +98", value: "+98"},
    {label: "Iraq    +964", value: "+964"},
    {label: "Irlanda +353", value: "+353"},
    {label: "Isole Cayman +1-345", value: "+1-345"},
    {label: "Isole Falkland +500", value: "+500"},
    {label: "Isole Fiji +679", value: "+679"},
    {label: "Islansa +354", value: "+354"},
    {label: "Israele +972", value: "+972"},
    {label: "Italia +39", value: "+39"},
    {label: "Jugoslavia        +38", value: "+38"},
    {label: "Kazakistan        +7", value: "+7"},
    {label: "Kenya   +254", value: "+254"},
    {label: "Kirghizistan      +966", value: "+966"},
    {label: "Kuwait  +965", value: "+965"},
    {label: "Laos    +856", value: "+856"},
    {label: "Lettonia          +371", value: "+371"},
    {label: "Libano  +961", value: "+961"},
    {label: "Liberia +231", value: "+231"},
    {label: "Libia   +218", value: "+218"},
    {label: "Liechtenstein     +41", value: "+41"},
    {label: "Lussemburgo       +352", value: "+352"},
    {label: "Macao   +853", value: "+853"},
    {label: "Macedonia         +389", value: "+389"},
    {label: "Madagascar        +261", value: "+261"},
    {label: "Malawi  +265", value: "+265"},
    {label: "Malaysia          +60", value: "+60"},
    {label: "Maldive +960", value: "+960"},
    {label: "Mali    +223", value: "+223"},
    {label: "Malta   +356", value: "+356"},
    {label: "Marocco +212", value: "+212"},
    {label: "Martinique        +596", value: "+596"},
    {label: "Mauritania        +222", value: "+222"},
    {label: "Mauritius         +230", value: "+230"},
    {label: "Messico +52", value: "+52"},
    {label: "Micronesia        +691", value: "+691"},
    {label: "Moldavia          +373", value: "+373"},
    {label: "Monaco +33", value: "+33"},
    {label: "Mongolia          +976", value: "+976"},
    {label: "Mozanbico         +258", value: "+258"},
    {label: "Namibia +264", value: "+264"},
    {label: "Nepal   +977", value: "+977"},
    {label: "Nicaragua         +505", value: "+505"},
    {label: "Niger   +227", value: "+227"},
    {label: "Nigeria +234", value: "+234"},
    {label: "Norvegia +47", value: "+47"},
    {label: "Nuova Zelanda     +64", value: "+64"},
    {label: "Olanda +31", value: "+31"},
    {label: "Oman    +968", value: "+968"},
    {label: "Pakistan          +92", value: "+92"},
    {label: "Panama  +507", value: "+507"},
    {label: "Papua Nuova Guinea    +675", value: "+675"},
    {label: "Paraguay          +595", value: "+595"},
    {label: "Peru\"   +551", value: "+551"},
    {label: "Polinesia +689", value: "+689"},
    {label: "Polonia +48", value: "+48"},
    {label: "Portogallo +351", value: "+351"},
    {label: "Portorico         +1-787", value: "+1-787"},
    {label: "Qatar   +974", value: "+974"},
    {label: "Reunion +262", value: "+262"},
    {label: "Rep. Ceca +420", value: "+420"},
    {label: "Rep. Dominicana +1-809", value: "+1-809"},
    {label: "Romania +40", value: "+40"},
    {label: "Ruanda  +250", value: "+250"},
    {label: "Russia  +7", value: "+7"},
    {label: "San Marino +378", value: "+378"},
    {label: "Seicelle +248", value: "+248"},
    {label: "Senegal +221", value: "+221"},
    {label: "Serbia  +381", value: "+381"},
    {label: "Sierra Leone      +232", value: "+232"},
    {label: "Singapore         +65", value: "+65"},
    {label: "Siria   +963", value: "+963"},
    {label: "Slovacchia Rep    +421", value: "+421"},
    {label: "Slovenia          +386", value: "+386"},
    {label: "Somalia +252", value: "+252"},
    {label: "Spagna +34", value: "+34"},
    {label: "Sri Lanka         +94", value: "+94"},
    {label: "Sud Africa        +27", value: "+27"},
    {label: "Sudan   +249", value: "+249"},
    {label: "Suriname          +597", value: "+597"},
    {label: "Svezia +46", value: "+46"},
    {label: "Svizzera +41", value: "+41"},
    {label: "Swaziland         +268", value: "+268"},
    {label: "Taiwan  +886", value: "+886"},
    {label: "Tagikistan        +992", value: "+992"},
    {label: "Tanzania          +255", value: "+255"},
    {label: "Thailandia        +66", value: "+66"},
    {label: "Togo    +228", value: "+228"},
    {label: "Trinidad & Tobago +1-868", value: "+1-868"},
    {label: "Tunisia +216", value: "+216"},
    {label: "Turchia +90", value: "+90"},
    {label: "Turkmenistan      +993", value: "+993"},
    {label: "Ucraina +380", value: "+380"},
    {label: "U.S.A. +1", value: "+1"},
    {label: "Uganda  +256", value: "+256"},
    {label: "Ungheria          +36", value: "+36"},
    {label: "Uruguay +598", value: "+598"},
    {label: "Uzbekistan        +998", value: "+998"},
    {label: "Venezuela         +58", value: "+58"},
    {label: "Vietnam Dem.      +84", value: "+84"},
    {label: "Wallis & Futuna   +681", value: "+681"},
    {label: "Yemen +967", value: "+967"},
    {label: "Zaire   +243", value: "+243"},
    {label: "Zambia  +260", value: "+260"},
    {label: "Zimbawe +263", value: "+263"}
  ]

  const onSubmit = (data) => {
    const website = "Pokermagia";
    const ThankYouPage = `/${lng}/thank-you-wa`;
    const PlayerAlreadyRegistered = `/${lng}/player-already-registered`;
    const PlayerAlreadyRegisteredWa = `/${lng}/player-already-registered-wa`;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const rf = params.rf ? params.rf : '';
    let form_data_ok = true;
    let phone_prefix = data.prefix;
    const phone = data.whatsapp;
    let phone_nr_length = 10;
    switch(phone_prefix) {
      case "+34": //Spagna
        phone_nr_length = 9;
        break;
      case "+55":
        phone_nr_length = 11; //Brasile
        break;
      case "+56": //Cile
        phone_nr_length = 9;
        break;
      default:
        phone_nr_length = 10;
    } 

    if (phone !== "") {
      if( !(phone.match('[0-9]')) ){
        alert('Perfavore inserire un numero di telefono valido');
        document.getElementsByName("whatsapp")[0].focus();
        form_data_ok = false;
      }else{
        if (phone.length != phone_nr_length) {
          alert('Perfavore inserire un numero di telefono valido');
          document.getElementsByName("whatsapp")[0].focus();
          form_data_ok = false;
        }
      }
    } else {
      phone_prefix = "";
    }

    if(form_data_ok == true ){
      var arr = ['ITA','ESP','LAT','BRA'];
      if (arr.indexOf(data.country) > -1) {
        const player = {first_name: data.first_name, email: data.email, website: website, fb_ap: data.country, whatsapp: data.whatsapp, prefix: phone_prefix, refer: rf}
        document.getElementById("btnSubmit").disabled = true; 
  
        axios.post(process.env.REACT_APP_CRM_API_URL + "/player", {player: player})
          .then(res => {
            if(res.data[0]["status"] === "200"){
              window.location.href = ThankYouPage;
            }
  
            if(res.data[0]["status"] === "409"){
              window.location.href = PlayerAlreadyRegistered;
            }
  
            if(res.data[0]["status"] === "410"){
              window.location.href = PlayerAlreadyRegisteredWa;
            }
          })
      }
    }
  };

  return (
    <>
      <div className="header-landing">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
        </div>
      </div>

      <section className="position-relative new-hero-sec">
        <div
          className="bg-img"
          style={{ backgroundImage: `url(${heroImg})` }}
        ></div>
        <div className="container">
          <div className="new-hero-text position-relative">
            <h2 className="text-uppercase">
              {t("landing.title1.1")}
              <strong>{t("landing.title1.2")}</strong>
            </h2>
            <p>{t("landing.header.text")}</p>
            <button onClick={scrollSmoothHandler} className="theme-btn landing">
              {t("landing.header.btn")}
            </button>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="about-sec text-center">
            <div className="about-title">
              <img src={aboutImg} alt="" />
              <p>{t("landing.scegliere1")}</p>
              <p>{t("landing.scegliere2")}</p>
              <p>{t("landing.scegliere3")}</p>
            </div>
            <div className="about-txt">
              <p>
                <Trans t={t}>{t("landing.p1")}</Trans>
              </p>
              <p>
                <Trans t={t}>{t("landing.p2")}</Trans>
              </p>
              <p>
                <Trans t={t}>{t("landing.p3")}</Trans>
              </p>
            </div>
          </div>
        </div>  
      </section>

      <section className="benefits-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-1 order-2">
              <div className="benefits-txt">
                <div className="new-title">
                  <h2 className="text-uppercase">
                    {t("landing.vantaggi.title")}
                  </h2>
                </div>
                <ul className="benefits-list list-unstyled p-0 m-0">
                  <li>
                    <span>
                      <img src={icon1} alt="" />
                    </span>
                    {t("landing.vantaggi.1")}
                  </li>
                  <li>
                    <span>
                      <img src={icon2} alt="" />
                    </span>
                    {t("landing.vantaggi.2")}
                  </li>
                  <li>
                    <span>
                      <img src={icon3} alt="" />
                    </span>
                    {t("landing.vantaggi.3")}
                  </li>
                  <li>
                    <span>
                      <img src={icon4} alt="" />
                    </span>
                    {t("landing.vantaggi.4")}
                  </li>
                </ul>
                <a href="#form" className="theme-btn">
                  {t("landing.vantaggi.btn")}
                </a>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <img src={beneImg} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="about2-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src={aboutImg2} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6">
              <div className="txt">
                <p>
                  <Trans t={t}>{t("landing.p4")}</Trans>
                </p>
                <p>
                  <Trans t={t}>{t("landing.p5")}</Trans>
                </p>
                <p>
                  <Trans t={t}>{t("landing.p6")}</Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-sec text-center">
        <div className="container">
          <h2 className="text-uppercase">{t("landing.feedback.title")}</h2>
          <div className="testimonials">
            <div className="test-item">
              <img src={quoteIcon} alt="" />
              <p>
                "È stata un'esperienza molto piacevole e utile, grazie al materiale di studio e alle lezioni ho raggiunto e superato gli obiettivi che avevo fissato e sono entrato molto presto nel team ufficiale di PokerMagia."
              </p>
              <strong>- Gennaro "Mastino134" Lomasti -</strong>
            </div>
          </div>
        </div>
      </section>

      <section  ref={scrollDiv} className="contact-sec" id="form">
        <div className="container">
          <div className="contact-content position-relative text-center">
            <img src={contactImg1} alt="" />
            <h2 className="text-uppercase">{t("landing.form.title")}</h2>
            <form onSubmit={handleSubmit(onSubmit)}
              className="contact-form"
            >
              <div className="form-row justify-content-center">
                <div className="form-group col-sm-6">
                  <input
                    maxLength="200"
                    type="text"
                    id="FIRSTNAME"
                    name="FIRSTNAME"
                    autoComplete="off"
                    placeholder={t("landing.form.name")}
                    data-required="true"
                    {...register("first_name")}
                    required
                  />
                </div>
         
                <div className="form-group col-sm-6">
                  <input
                    type="email"
                    id="EMAIL"
                    name="EMAIL"
                    autoComplete="off"
                    placeholder="Email"
                    data-required="true"
                    {...register("email")}
                    required
                  />
                </div>

                <div className="form-group col-sm-3">
                  <select name="PREFIX" id="PREFIX" data-required="true" defaultValue="+39" required {...register("prefix")}>
                    {international_prefix.map((pref) => (
                      <option key={pref.value+pref.label} value={pref.value}>{pref.label}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-sm-3">
                  <input
                    maxLength="11"
                    type="text"
                    id="WHATSAPP"
                    name="WHATSAPP"
                    autoComplete="off"
                    placeholder="Whatsapp"
                    // data-required="true"
                    {...register("whatsapp")}
                  />
                </div>

                <div className="form-group col-sm-3">
                  <select name="COUNTRY" data-required="true" required {...register("country")}>
                    <option value="">{t("landing.country")}</option>
                    <option value="ITA">{t("landing.country.ita")}</option>
                    <option value="ESP">{t("landing.country.esp")}</option>
                    {/* <option value="BRA">{t("landing.country.bra")}</option> */}
                    <option value="LAT">{t("landing.country.lat")}</option>
                  </select>
                </div>

                <div className="form-group col-sm-4">
                  <input type="checkbox" id="checkbox1" name="privacy" required />
                  <label htmlFor="checkbox1"> 
                    {t("landing.form.privacy1")}
                    <a href="https://www.iubenda.com/privacy-policy/63636631"> {t("landing.form.privacy2")}</a>
                  </label>
                </div>

                <input
                  type="text"
                  name="email_address_check"
                  style={{ display: "none" }}
                />
                <input type="hidden" name="locale" value={lng} />
                <input type="hidden" name="html_type" value="simple" />
                <div className="col-sm-4">
                  

                  <button className="theme-btn d-block" id="btnSubmit">
                    {t("landing.form.btn")}
                  </button>
              
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <footer className="position-relative">
        <div
          className="bg-img"
          style={{ backgroundImage: `url(${footerBg})` }}
        ></div>
        <div className="container">
          <div className="footer-text text-center">
            <p>{t("landing.footer.1")}</p>
            <p>{t("landing.footer.2")}</p>
            {lng === "it" && (
              <img src={footerImg} alt="" className="img-fluid" />
            )}
            <p>{t("landing.footer.3")}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingWa;
